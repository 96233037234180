import React from 'react';
import {
  Container, Row, Col, Card, Button,
} from 'react-bootstrap';
import './our-services.scss';
import { navigate } from 'gatsby';
import { OurServicesData, ServicesData } from '../../../data/db';

export default function OurServices() {
  return (
    <section className="our-services" id="ourservices">
      <Container>
        <Row>
          <Col className="col-12 col-lg-8 col-xxl-6 order-last order-lg-first">
            <Row>
              {OurServicesData.map(({
                id, heading, image, text,
              }) => (
                <Col key={id} className="col-12 col-sm-6 services-column">
                  <Card
                    className="services-card shadow rounded"
                    border="light"
                  >
                    <img src={image} className="img1" alt="Card" />
                    <Card.Body>
                      <Card.Title className="fs-5">{heading}</Card.Title>
                      <Card.Text className="fs-6">{text}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

          </Col>
          <Col className="description-area col-12 col-lg-4 offset-xxl-1 col-xxl-5">
            <h2 className="fs-2 mb-5">{ServicesData[0].heading}</h2>
            <p className="p1 custom-text-justify fs-6">{ServicesData[0].text}</p>
            <Button variant="primary" className="infenox-button my-4" role="link" onClick={() => { navigate('/contact'); }}>{ServicesData[0].buttonLabel}</Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
